import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-full">
      <img
        src="https://www.vizion.com/wp-content/smush-webp/2018/09/shutterstock_479042983.jpg.webp" // Replace with an actual image URL
        alt="Page Not Found"
        className="w-[70%] md:w-[70%] mb-2 h-[70%]" // Responsive sizing
      />
      <a href="/" className="mt-4 text-blue-500 hover:underline">
        Go Back to Home
      </a>
    </div>
  );
};

export default NotFound;

// src/pages/InProgressMissions.js
import { useEffect, useState } from "react";
import Table from "../components/Table";
import { BASE_URL, getToken } from "../utils/constants";
import Loader from "../components/Loader";

const InProgressMissions = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async() => {
    const token = await getToken();
    try{
      setIsLoading(true);
      const response = await fetch(BASE_URL + "/api/missions/false", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      setData(result);
      setIsLoading(false);
    }catch{
        alert('Ooops something happen please try again!')
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if(isLoading){
    return <Loader/>;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">In Progress Missions</h2>
      <Table data={data} />
    </div>
  );
};

export default InProgressMissions;

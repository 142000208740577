// src/App.js
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import DoneMissions from "./pages/DoneMissions";
import InProgressMissions from "./pages/InProgressMissions";
import ArchivedMissions from "./pages/ArchivedMissions";
import { useEffect, useState } from "react";
import AdminLogin from "./pages/Login";
import { logout } from "./utils/constants";
import PasswordUpdate from "./pages/PassowrdUpdate";
import NotFound from "./pages/NotFound";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getToken = async () => {
   const token = await localStorage.getItem("token");
   if(token){
    setIsLoggedIn(true);
   } else {
     setIsLoggedIn(false);
   }
  };

  useEffect(() => {
    getToken();
  },[]);

  if (!isLoggedIn){
     return (
       <Router>
         <Routes>
           <Route path="/" element={<AdminLogin />} />
           <Route path="/*" element={<NotFound />} />
         </Routes>
       </Router>
     );
  }
    return (
      <Router>
        <div className="flex">
          <Sidebar />
          <div className="flex-1 p-6 overflow-x-auto">
            <Routes>
              <Route path="/" element={<InProgressMissions />} />
              <Route path="/done-missions" element={<DoneMissions />} />
              <Route path="/archived-missions" element={<ArchivedMissions />} />
              <Route path="/update-password" element={<PasswordUpdate />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
    );
};

export default App;

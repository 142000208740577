// src/pages/DoneMissions.js
import Table from "../components/Table";
import React ,{useState, useEffect} from 'react'
import { BASE_URL, getToken } from "../utils/constants";
import Loader from "../components/Loader";
const DoneMissions = () => {
 const [data, setData] = useState([]);
 const [isLoading, setIsLoading] = useState(false);

 const getData = async () => {
     const token = await getToken();
    try{
     setIsLoading(true);
     const response = await fetch(BASE_URL + "/api/missions/true", {
       headers: {
         Authorization: `Bearer ${token}`,
       },
     });
     const result = await response.json();
     setData(result);
     setIsLoading(false);
    }catch{
     setIsLoading(false);
    }
 };

 useEffect(() => {
   getData();
 }, []);

 if(isLoading){
    return <Loader/>;
 }

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Done Missions</h2>
      <Table data={data} />
    </div>
  );
};

export default DoneMissions;

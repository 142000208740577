import React from "react";

const EmptyComponent = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <p className="text-gray-500 text-xl">No data</p>
    </div>
  );
};

export default EmptyComponent;

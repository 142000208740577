// src/components/Sidebar.js
import { Link } from "react-router-dom";
import { logout } from "../utils/constants";

const Sidebar = () => {
  return (
    <div className="flex flex-col items-start w-64 h-screen bg-gray-800 text-white">
      <div className="p-4 h-1/7 flex w-full justify-center">
        <h1 className="text-3xl font-bold">Trans-Alfan</h1>
      </div>
      <div className="flex flex-col w-full justify-between items-center p-2 h-4/5">
        <ul className="mt-4">
          <li>
            <Link to="/" className="block p-4 hover:bg-gray-700">
              In Progress Missions
            </Link>
          </li>
          <li>
            <Link to="/done-missions" className="block p-4 hover:bg-gray-700">
              Done Missions
            </Link>
          </li>
          <li>
            <Link
              to="/archived-missions"
              className="block p-4 hover:bg-gray-700"
            >
              Archived Missions
            </Link>
          </li>
          <li>
            <Link to="/update-password" className="block p-4 hover:bg-gray-700">
              Settings
            </Link>
          </li>
        </ul>

        <button
          onClick={() => logout()}
          className="w-1/2 bg-blue-600 flex items-center justify-center hover:bg-blue-300 duration-700"
        >
          <span className="font-bold text-md hover:text-red-500"> Logout </span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
